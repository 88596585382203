<div class="surface-section px-4 py-8 md:px-6 lg:px-8 text-center">
  <div class="mb-3 font-bold text-3xl">
    <span class="text-900">One Product, </span>
    <span class="text-blue-600">Many Solutions</span>
  </div>
  <div class="text-700 mb-6">
    Discover how Auto-Mate can help your business
  </div>
  <div class="grid">
    <div class="col-12 md:col-6 mb-6 px-5">
      <span
        class="p-3 shadow-2 mb-3 inline-block surface-card"
        style="border-radius: 10px"
      >
        <i class="pi pi-users text-4xl text-blue-500"></i>
      </span>
      <div class="text-900 text-xl mb-3 font-medium">Built for Owners</div>
      <span class="text-700 line-height-3"
        >Duis aute irure dolor in reprehenderit in voluptate velit esse cillum
        dolore eu fugiat nulla pariatur.</span
      >
    </div>
    <div class="col-12 md:col-6 mb-6 px-5">
      <span
        class="p-3 shadow-2 mb-3 inline-block surface-card"
        style="border-radius: 10px"
      >
        <i class="pi pi-chart-pie text-4xl text-blue-500"></i>
      </span>
      <div class="text-900 text-xl mb-3 font-medium">Understand your business</div>
      <span class="text-700 line-height-3"
        >Risus nec feugiat in fermentum posuere urna nec. Posuere sollicitudin
        aliquam ultrices sagittis.</span
      >
    </div>
    <div class="col-12 md:col-6 mb-6 px-5">
      <span
        class="p-3 shadow-2 mb-3 inline-block surface-card"
        style="border-radius: 10px"
      >
        <i class="pi pi-check-circle text-4xl text-blue-500"></i>
      </span>
      <div class="text-900 text-xl mb-3 font-medium">Easy to Use</div>
      <span class="text-700 line-height-3"
        >Ornare suspendisse sed nisi lacus sed viverra tellus. Neque volutpat ac
        tincidunt vitae semper.</span
      >
    </div>
    <div class="col-12 md:col-6 mb-6 px-5">
      <span
        class="p-3 shadow-2 mb-3 inline-block surface-card"
        style="border-radius: 10px"
      >
        <i class="pi pi-bolt text-4xl text-blue-500"></i>
      </span>
      <div class="text-900 text-xl mb-3 font-medium">Automation management</div>
      <span class="text-700 line-height-3"
        >Fermentum et sollicitudin ac orci phasellus egestas tellus rutrum
        tellus.</span
      >
    </div>
    <div class="col-12 md:col-6 mb-6 px-5">
      <span
        class="p-3 shadow-2 mb-3 inline-block surface-card"
        style="border-radius: 10px"
      >
        <i class="pi pi-cloud text-4xl text-blue-500"></i>
      </span>
      <div class="text-900 text-xl mb-3 font-medium">Well connected</div>
      <span class="text-700 line-height-3"
        >Nec tincidunt praesent semper feugiat. Sed adipiscing diam donec
        adipiscing tristique risus nec feugiat.
      </span>
    </div>
    <div class="col-12 md:col-6 md:mb-6 mb-0 px-3">
      <span
        class="p-3 shadow-2 mb-3 inline-block surface-card"
        style="border-radius: 10px"
      >
        <i class="pi pi-shield text-4xl text-blue-500"></i>
      </span>
      <div class="text-900 text-xl mb-3 font-medium">Trusted Security</div>
      <span class="text-700 line-height-3"
        >Mattis rhoncus urna neque viverra justo nec ultrices. Id cursus metus
        aliquam eleifend.</span
      >
    </div>
  </div>
</div>
