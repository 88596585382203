<p-sidebar #sidebarRef [(visible)]="sidebarVisible" position="right">
  <ng-template pTemplate="header">
    <img id="sidebarLogo" src="assets/logo/helixide/HLX-logo.svg" />
  </ng-template>

  <ng-template pTemplate="content">
    <p-menu [model]="items" [style]="{ width: '100%' }"></p-menu>
  </ng-template>
  <ng-template pTemplate="footer">
    <p-divider type="solid" align="center">v{{ appVersion }}</p-divider>
    <div class="userInfo">
      <p-avatar
        label="{{ userProfile?.firstName?.substring(0, 1) }}{{
          userProfile?.lastName?.substring(0, 1)
        }}"
        styleClass="mr-2"
        size="large"
        shape="circle"
      ></p-avatar>
      <div>
        <strong
          >{{ userProfile?.firstName }}
          <span class="nowrap">{{ userProfile?.lastName }}</span></strong
        ><br />
        <span class="nowrap userEmail">{{ userProfile?.email }}</span>
      </div>
    </div>
    <div class="footerButtons">
      <p-button
        label="Logout"
        [text]="true"
        severity="danger"
        (click)="this.logout()"
        size="small"
      ></p-button>
    </div>
  </ng-template>
</p-sidebar>
<p-button (click)="sidebarVisible = true" icon="pi pi-bars"></p-button>
