import { Routes } from '@angular/router';
import { DashboardComponent } from './internal/dashboard/dashboard.component';
import { ImportComponent } from './prestashop/import/import.component';
import { AuthGuardService } from '../services/auth/auth-guard.service';
import { OverviewComponent } from './home/overview.component';

const appName = $localize`:The app name@@appName:Auto-Mate`;
const appCredit = $localize`:As something made by Helixide@@appCredit:by Helixide`;

export const routes: Routes = [
    { 
        path: '',
        title: generateFullTitle($localize `:The overview of different scope of the business@@Overview:Overview`),
        component: OverviewComponent,
        canActivate: [AuthGuardService],
        data: { roles: [] },
    },
    {
        path: 'internal/dashboard-kpi',
        component: DashboardComponent,
        title: generateFullTitle('Dashboard KPI'),
        canActivate: [AuthGuardService],
        data: { roles: [] },
    },
    {
        path: 'ps/import',
        component: ImportComponent,
        title: generateFullTitle('Import'),
        canActivate: [AuthGuardService],
        data: { roles: [] },
    }
];

function generateFullTitle(title:string | null = null): string{
    if(title && title.length > 0){
        return `${appName} - ${title}`;
    }else{
        return `${appName} ${appCredit}`;
    }
}