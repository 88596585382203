import { Component} from '@angular/core';
import { AutoMateService } from '../../services/api/auto-mate.service';

@Component({
  selector: 'app-overview',
  standalone: true,
  imports: [],
  providers: [AutoMateService],
  templateUrl: './overview.component.html',
  styleUrl: './overview.component.scss'
})
export class OverviewComponent {
  
}
