import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

export interface Config {
  data?: object;
  msg?: string;
}

@Injectable({
  providedIn: 'root'
})
export class AutoMateService {

  private baseURL: string = "https://api.automate.develop.helixi.de";
  //private baseURL: string = "http://automate.local.helixi.de:3000";
  
  constructor(private http: HttpClient) { 
  }

  getAutoMateAPI(){
    
    return this.http.get<Config>(this.baseURL);
  }
}
