import { Component } from '@angular/core';
import { SidebarModule } from 'primeng/sidebar';
import { ButtonModule } from 'primeng/button';
import { AvatarModule } from 'primeng/avatar';
import { RippleModule } from 'primeng/ripple';
import { MenuModule } from 'primeng/menu';

import { MenuItem } from 'primeng/api';
import { DividerModule } from 'primeng/divider';
import { KeycloakService } from 'keycloak-angular';
import { KeycloakProfile } from 'keycloak-js';
import { APP_VERSION } from '../../../version';

@Component({
  selector: 'app-sidebar',
  standalone: true,
  imports: [SidebarModule, ButtonModule, AvatarModule, RippleModule, MenuModule, DividerModule],
  templateUrl: './sidebar.component.html',
  styleUrl: './sidebar.component.scss'
})
export class SidebarComponent {
  appVersion:string = APP_VERSION;
  sidebarVisible: boolean = false;

  items!: MenuItem[];

  public userProfile: KeycloakProfile | null = null;
  constructor(private readonly keycloak: KeycloakService) { }

  public logout() {
    this.keycloak.logout(window.location.origin);
  }

  async ngOnInit() {

    this.userProfile = await this.keycloak.loadUserProfile();

    this.items = [
      {
        label: '',
        icon: '',
        items: [
          {
            label: $localize `:The overview of different scope of the business@@Overview:Overview`,
            icon: 'pi pi-fw pi-briefcase',
            routerLink: [''],
            routerLinkActiveOptions: { exact: true },
            command: (event) => {
              this.sidebarVisible = false;
            }
          }
        ]
      },
      {
        label: 'Internal',
        icon: 'pi pi-fw pi-briefcase',
        items: [
          {
            label: 'Dashboard KPI',
            icon: 'pi pi-fw pi-chart-line',
            routerLink: ['internal/dashboard-kpi'],
            routerLinkActiveOptions: { exact: true },
            command: (event) => {
              this.sidebarVisible = false;
            }
          }
        ]
      },
      {
        label: 'PrestaShop',
        icon: 'pi pi-fw pi-prime',
        items: [
          {
            label: 'Import',
            icon: 'pi pi-fw pi-cloud-upload',
            routerLink: ['ps/import'],
            routerLinkActiveOptions: { exact: true },
            command: (event) => {
              this.sidebarVisible = false;
            }
          },
          {
            label: 'Advanced search',
            disabled: true,
            icon: 'pi pi-fw pi-search',
            command: (event) => {
              //this.sidebarVisible = false;
            }
          }
        ]
      }
    ];
  }

}
