import { DatePipe } from '@angular/common';
import { Component } from '@angular/core';
import { DividerModule } from 'primeng/divider';


@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [DatePipe, DividerModule],
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss'
})
export class FooterComponent {
  appOwner: string = "Helixide SRL";
  appOwnerUrl: string = "https://www.helixide.com/?utm_source=auto-mate&utm_medium=footer&utm_campaign=copyright"
  appCreationDate: Date = new Date("2024-02-02");
  copyrightYear: string = getCopyrightYear(this.appCreationDate);
}

function getCopyrightYear(creationDate: Date): string {
  const currentFullYear = new Date().getFullYear();
  const creationFullYear = creationDate.getFullYear();
  if (creationFullYear === currentFullYear) {
    return creationFullYear.toString();
  } else if (creationFullYear < currentFullYear) {
    return `${creationFullYear.toString()}-${currentFullYear.toString()}`;
  } else {
    return `${creationFullYear} ${$localize`(in the future!)`}`;
  }
}
