import { CurrencyPipe, DatePipe } from '@angular/common';
import { Component, Inject, LOCALE_ID, inject } from '@angular/core';
import { CardModule } from 'primeng/card';
import { ButtonModule } from 'primeng/button';
import { DividerModule } from 'primeng/divider';
import { SkeletonModule } from 'primeng/skeleton';
import { AutoMateService } from '../../../services/api/auto-mate.service';



@Component({
  selector: 'app-dashboard',
  standalone: true,
  imports: [CurrencyPipe, DatePipe, CardModule, ButtonModule, DividerModule, SkeletonModule],
  providers: [AutoMateService],
  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.scss'
})
export class DashboardComponent {
  loading: boolean = true;
  appName = $localize`:The app name@@appName:Auto-Mate`;
  automate:object | undefined;
  AutoMateService: AutoMateService = inject(AutoMateService) ;
  constructor(@Inject(LOCALE_ID) public locale: string) {
    
  }

   ngOnInit() {
     this.getAutoMate();
   }

  public getAutoMate(): void {
    this.loading = true;
    this.AutoMateService.getAutoMateAPI().subscribe(
      (response) => { 
        this.automate = response.data;
        this.loading = false; },
      (error) => { 
        
        console.log(error);
         });
  }
}